import React from 'react'
import FavoriteHerbs from './FavoriteHerbs'
import { SettingsContainer } from '../../Account'
import { PageHeaderTypeThree } from '../../Layout/GlobalStyle'
import { ManageFavoritesStyled, FavoritesTabBar, FavoriteTab } from '../favoriteStyles'

export default function ManageFavorites() {
    return (
        <SettingsContainer>
            <ManageFavoritesStyled>



                <PageHeaderTypeThree>Favorites</PageHeaderTypeThree>
                <div className="favorites-tab-wrapper">

                    <FavoritesTabBar>
                        <FavoriteTab>
                            Herbs
                    </FavoriteTab>
                    </FavoritesTabBar>
                    <div className="favorites-tab-content">
                        <FavoriteHerbs />
                    </div>

                </div>

            </ManageFavoritesStyled>

        </SettingsContainer>
    )
}
