import styled from 'styled-components'
import { BreakpointMobile } from '../Layout/GlobalStyle';

export const FavoritesTabBar = styled.nav`
    background: #F3F3F3;
    height: 40px;
    width: 100%;
    display: inline-flex;
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-top: 1px solid #DDDDDD;
   
`

export const FavoriteTab = styled.div`
    background: white;
    height: inherit;
    font-size: 17px;
    color: var(--TextTitleColor2);
    width: 200px;
    display: flex;
    font-family: var(--Font1NotCondensed);
    justify-content: center;
    font-weight: bold;
    align-items: center;
`

export const ManageFavoritesStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    .MuiTypography-root ,.MuiTypography-h6{
        color: blue !important;
    }
    .favorites-tab-wrapper{
        display: inline-flex;
        flex-direction: column;

    }
    .favorites-tab-content{
        border: 1px solid #DDDDDD;
        border-top: 0px solid transparent;
    }

    .favorite-list{
        padding: 20px;
    }

    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        .favorite-list{
            padding: 10px;
        }

    }
`


export const NoFavoritesContainer = styled.div`
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 220px;
    padding-bottom: 48px;

    .call-to-action-wrapper{
        a{
            color: var(--Color1);
            font-size: 14px;
            font-weight: bold;
            text-decoration: underline;

            :hover{
                color: var(--Color1Darker);
            }
        }
    }
    .text-and-cta{
        h3{
            text-align: center;
            font-size: 20px;
            margin-bottom: 0px;
            margin-top: 0px;
            line-height: 24px;
        }
        p{
            text-align: center;
            font-family: var(--Font1NotCondensed);
            font-size: 15px;
            color: #4a4a4a;
            line-height: 25px;
        }
    }

    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        padding-top: 36px;
    }
`

export const HerbFavoriteItemStyled = styled.div`
    height: 40px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    max-width: 300px;
    cursor: pointer;
    .img-and-text{
        display: flex;
        width: 100%;
    }

    .list-text{
        padding-left: 8px;
        font-size: 15px;
        color: #4D4D4D;
        a{
            color: inherit;
        }
    }

    .list-image{
        transition: 0.3s ease-in-out;
        border-radius: 2px;
        width: 30px;
        height: 30px;
        background-image: url(${props => props.theme.defaultHerbListIcon});      
        background-repeat: none;
        background-size: cover;
        ${props => props.src ? `background-image: url(${props.src})` : `background-image: url(${props => props.theme.defaultHerbListIcon})`};
       
    }

    :hover{
        transition: 0.2s ease-in-out;

        background: rgba(0, 0, 0, 0.05);
    }

    :active{
        background: rgba(0, 0, 0, 0.3);
    }

    .favorite-button-actions{
        height: inherit;
    }
    .delete-button{
        transition: 0.2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px solid transparent;
        height: inherit;
        cursor: pointer;
        background: transparent;
        border-radius: 0px 4px 4px 0px;

        :hover{
            background: var(--ErrorColor);
            svg{
                #Path_14{
                    fill: white !important;

                }
            }
        }
    }

    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        max-width: initial;
    }
`


export const FavoritesContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`