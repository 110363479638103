import React from 'react'
import { NoFavoritesContainer } from '../../favoriteStyles';
import NoFavoritesIcon from 'react-ionicons/lib/MdHeartOutline'
import {Link} from 'gatsby'
import { HERBS } from '../../../Layout/routes';


export default function NoFavoriteHerbsFound() {

   
    return (
            <NoFavoritesContainer>
                <NoFavoritesIcon fontSize={150} color={"rgba(178, 179, 179, 0.5)"} />
                <div className="text-and-cta">
                   <h3>No Favorites</h3> 
                   <p>
                   Add your favorite herbs so<br/>
You can easily find them
                      
                   </p>
                </div>
                <div className="call-to-action-wrapper">
                    <Link to={HERBS}>

                    Click here to find some herbs
                    </Link>

                </div>
                
                
            </NoFavoritesContainer>
    )
}
