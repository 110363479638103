import React, { useEffect, useState } from 'react'
import { HerbFavoriteItemStyled } from '../../favoriteStyles'
import { getHerbs, getHerbBySlug } from '../../../../services/herb.services';
import { Link } from 'gatsby'
import { TrashIcon } from '../../../Layout/GlobalStyle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import VisibilitySensor from 'react-visibility-sensor';
import PropTypes from 'prop-types'


const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontFamily: 'Roboto !important',
    fontWeight: 'bold',
  },
  cancelButton: {
    fontFamily: "var(--Font1NotCondensed)",
    color: '#4D4D4D',
    fontWeight: 'bold'

  },
  removeButton: {
    fontFamily: "var(--Font1NotCondensed)",
    color: 'var(--ErrorColor)',
    fontWeight: 'bold'
  }

}));
function FavoriteHerbItem(props) {
  const classes = useStyles()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  let { item, removeHerbFromFavorites } = props;
  const [picture, setPicture] = useState("")
  const [hasCheckedForPicture, setHasCheckedForPicture] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      loadData()
    }

    return () => isSubscribed = false;
  }, [])

  const handleDeleteClickOpen = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteClose = () => {
    setOpenDeleteDialog(false);
  };




  const loadData = () => {
    setLoading(true)
    getHerbBySlug(props.item.herb.slug, `?picturesOnly=true`)
      .then(res => {
        if (res.status === 200) {
          setLoading(false)

          if (res.data && res.data.pictures && res.data.pictures[0]) {
            setPicture(res.data.pictures[0].src)

          }
          setHasCheckedForPicture(true)

        }
      })
      .catch(err => {
        setHasCheckedForPicture(true)
        setLoading(false)
        throw err
      })
  }

  const onVisibilityChange = (isVisible) => {
    if (isVisible === true && !picture && !hasCheckedForPicture && !loading) {
      loadData(true)
    }
  }


  return (
    <>
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteClose}
        aria-labelledby={`herb-delete-title${item.herb._id}`}
        aria-describedby={`herb-delete-description${item.herb._id}`}
      >
        <DialogTitle style={{ color: "#4D4D4D", fontSize: 20, fontFamily: "Roboto" }} id={`herb-delete-title${item.herb._id}`}> Are you sure you want to remove <strong style={{ color: "var(--Color1)" }}>{item.herb.name}</strong>? </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontFamily: "var(--Font2)", color: '#757575' }} id={`herb-delete-description${item.herb._id}`}>
            You can always add it back later if you would like
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.cancelButton} onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button className={classes.removeButton} onClick={() => removeHerbFromFavorites(item.herb._id)} color="primary" autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <HerbFavoriteItemStyled src={picture}>

        <Link className="img-and-text" to={`/herb/${item.herb.slug}`}>
          <VisibilitySensor onChange={(e) => onVisibilityChange(e)}>

            <div className="list-image">

            </div>
          </VisibilitySensor>

          <div className="list-text">

            {item.herb.name}

          </div>

        </Link>


        <div className="favorite-button-actions">
          <button onClick={() => setOpenDeleteDialog(true)} className="delete-button">
            <TrashIcon />
          </button>
        </div>


      </HerbFavoriteItemStyled>
    </>
  )

}

FavoriteHerbItem.defaultProps = {
  item: {
    herb: "",
    user: "",
    _id: ""
  },
  removeHerbFromFavorites: () => { }
}

FavoriteHerbItem.propTypes = {
  item: PropTypes.shape({
      herb: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
      _id: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
      user: PropTypes.oneOf([PropTypes.string, PropTypes.number])
  }),
  removeHerbFromFavorites: PropTypes.func.isRequired
}


export default FavoriteHerbItem