import React, {useState, useEffect, useContext} from 'react'
import AuthUserContext from '../../providers/AuthProvider'
import ManageFavorites from './ManageFavorites'
import LogInToAddFavorites from './LogInToAddFavorites'
import {FavoritesContainer} from './favoriteStyles'
export default function Favorites() {
    let {user} = useContext(AuthUserContext)
    let handleDisplay = user.id? <ManageFavorites /> : <LogInToAddFavorites />

    return (
        <FavoritesContainer>
            {handleDisplay}
        </FavoritesContainer>
    )
}
