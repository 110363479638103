import React, {useContext, useState, useEffect} from 'react'
import AuthUserContext from '../../../../providers/AuthProvider'
import { getFavoriteHerbsForUser, deleteFavoriteHerb } from '../../../../services/favorite.services'
import FavoriteHerbItem from './FavoriteHerbItem'
import NoFavoriteHerbsFound from './NoFavoriteHerbsFound'
import LoadingContainer from '../../../common/LoadingContainer'

let defaultLimit = 100

export default function FavoriteHerbs() {
    let {user} = useContext(AuthUserContext)
    let [favoriteHerbs, setFavoriteHerbs] = useState([])
    let [count, setCount] = useState(0)
    let [limit, setLimit] = useState(defaultLimit)
    let [loadingHerbs, setLoadingHerbs] = useState(false)

    useEffect(() => {
        let isSubscribed = true;
        if(isSubscribed){
            loadData()
        }

        return () => isSubscribed = false;
    },[])

    const loadData = () => {
        setLoadingHerbs(true)
        getFavoriteHerbsForUser(`?limit=${limit}`)
            .then(res => {
                if(res.status === 200){
                    setFavoriteHerbs(res.data.favoriteHerbs)
                    setCount(res.data.count)
                    setLoadingHerbs(false)
                }
            })
            .catch(err => {
                setLoadingHerbs(false)

                throw err
            })
    }

    const removeHerbFromFavorites = (herbid) => {
        deleteFavoriteHerb(herbid)
            .then(res => {
                if(res.status === 202){
                    loadData()
                }
            })
            .catch(err => {
                throw err
            })
    }


    const renderFavoriteHerbs = favoriteHerbs.map((item, index) => {
        return <FavoriteHerbItem  removeHerbFromFavorites={removeHerbFromFavorites} key={index} item={item} />
    })


    return (
        <>
            {loadingHerbs? <LoadingContainer /> : count !== 0 && !loadingHerbs? <div className="favorite-list"> {renderFavoriteHerbs} </div> :
                <NoFavoriteHerbsFound />
            }
        </>
    )
}
