import {baseURL} from './baseURL'
import { getHeaders } from "../components/Layout";

const axios = require('axios')


export const getFavoriteHerbByHerbID = (id) => {
    return axios.get(`${baseURL}/api/favorite/herb/${id}`, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const getFavoriteHerbsForUser = (query) => {
    return axios.get(`${baseURL}/api/favorite/herbs/for-user${query? query: ''}`, getHeaders())
    .then(res => res)
    .catch(err => {
        throw err
    })
}


export const createFavoriteHerb = (body) => {
    return axios.post(`${baseURL}/api/favorite/herb`, body, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        })
}


export const deleteFavoriteHerb = (id) => {
    let options = getHeaders()
    return axios.delete(`${baseURL}/api/favorite/herb/${id}`, options)
        .then(res => res)
        .catch(err => {
            throw err
        })
}


