
import React from "react"
import Layout from "../components/Layout"
import Favorites from "../components/Favorites"

const FavoritesPage = () => {
  const seoProps = {
    title: `Herbs A-Z`,
  }
  return(
  <Layout seoProps={seoProps}>
    <Favorites />
  </Layout>)
}

export default FavoritesPage
