import React, { useContext } from 'react'
import { NoFavoritesContainer } from './favoriteStyles';
import NoFavoritesIcon from 'react-ionicons/lib/MdHeartOutline'
import Button from '@material-ui/core/Button';
import { navigate } from "@reach/router"
import { LOGIN } from '../Layout/routes';
import { makeStyles } from '@material-ui/core/styles';
import AuthUserContext from '../../providers/AuthProvider';

const useStyles = makeStyles((theme) => ({
    button: {
        fontFamily: "var(--Font1)",
        width: 200,
        fontWeight: 'bold',
        fontSize: 18,
        height: 40
    }

}));

function LogInToAddFavorites() {
    let { openSignInModal } = useContext(AuthUserContext)
    const classes = useStyles()





    return (
        <NoFavoritesContainer>
            <NoFavoritesIcon fontSize={150} color={"rgba(178, 179, 179, 0.5)"} />
            <div className="text-and-cta">
                <h3>No Favorites</h3>
                <p>
                    Login to add your <br />
                       favorite herbs
                   </p>
            </div>
            <Button onClick={() => openSignInModal("Favorite Herbs")} disableElevation variant="contained" color="secondary" className={classes.button} >
                Login
                </Button>
        </NoFavoritesContainer>
    )
}

export default LogInToAddFavorites
